// 引入路由模块并使用它
import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/view/Index'
import workInfo from '@/view/info/workInfo'
import goodInfo from '@/view/info/goodInfo'
import caseInfo from '@/view/info/caseInfo'
import worklist from '@/view/list/workList'
import goodList from '@/view/list/goodList'
import caseList from '@/view/list/caseList'
import worklist_black from '@/view/list/black/workList'
import goodList_black from '@/view/list/black/goodList'
import caseList_black from '@/view/list/black/caseList'
import workInfo_black from '@/view/info/black/workInfo'
import goodInfo_black from '@/view/info/black/goodInfo'
import caseInfo_black from '@/view/info/black/caseInfo'
import about from '@/view/about/index'
Vue.use(VueRouter)

const router = new VueRouter({
  routes:[{
      path: '/',
      name:'Index',
      component: Index,
      meta: {
				title: '首页'
			}
  },{
      path: '/workInfo',
      name:'workInfo',
      component: workInfo,
      meta:{
        title:'作品详情'
      }
  },{
    path: '/about',
    name:'about',
    component: about,
    meta:{
        title:'关于我们'
      }
},{
    path: '/worklist',
    name:'worklist',
    component: worklist,
    meta:{
        title:'作品列表'
      }
},{
  path: '/caselist',
  name:'caseList',
  component: caseList,
  meta:{
      title:'客片动态'
    }
},{
    path: '/goodList',
    name:'goodList',
    component: goodList,
    meta:{
        title:'服务套餐'
      }
},{
    path: '/goodInfo',
    name:'goodInfo',
    component: goodInfo,
    meta:{
        title:'服务详情'
      }
},{
  path: '/caseInfo',
  name:'caseInfo',
  component: caseInfo,
  meta:{
      title:'客片详情'
    }
},{
    path: '/black/worklist',
    name:'worklist_black',
    component: worklist_black,
    meta:{
        title:'作品列表'
      }
},{
    path: '/black/goodList',
    name:'goodList_black',
    component: goodList_black,
    meta:{
        title:'服务套餐'
      }
},{
  path: '/black/caselist',
  name:'caseList_black',
  component: caseList_black,
  meta:{
      title:'客片动态'
    }
},{
      path: '/black/workInfo',
      name:'workInfo_black',
      component: workInfo_black,
      meta:{
        title:'作品详情'
      }
  },{
    path: '/black/goodInfo',
    name:'goodInfo_black',
    component: goodInfo_black,
    meta:{
        title:'服务详情'
      }
},{
  path: '/black/caseInfo',
  name:'caseInfo_black',
  component: caseInfo_black,
  meta:{
      title:'客片详情'
    }
}
]
})

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前执行
  if(to.meta.title){//判断是否有标题
      document.title = to.meta.title
  }
  next()  //执行进入路由，如果不写就不会进入目标页
})

// 输出router
export default router;