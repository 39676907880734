<template>
    <div>
        <div
        class="des_header base_flex justify_flex"
        style="max-width: 1200px; margin: 0 auto; padding: 30px 0">
        <div class="left_title">
          <p class="title">
            <span class="verticalAlign title_tag"></span
            ><span class="verticalAlign title_text">
              <span :class="spanColor"></span>
              <span style="font-size: 20px; margin-left: 15px"
                >{{showTitle}}</span
              >
            </span>
          </p>
        </div>
        <router-link v-if="url !== undefined"  class="right_more_btn" :to="url">
          <span style="font-size: 16px; padding-right: 10px">更多</span>
        </router-link>
      </div>
    </div>
</template>
<script>
    export default {
        name:"Title",
        props:{
            settitle:String,
            tourl:String,
            themeType:{
              type:String,
              default:''
            }
        },
        data() {
            return {
                showTitle:this.settitle,
                url:this.tourl,
                spanColor: this.themeType !== '' ? 'defaultBg' + this.themeType :'defaultBg'
            };
        }
    }
</script>

<style>
.defaultBg{
  display: flex;
  width: 7px;
  height: 35px;
  background: #000000;
}
.defaultBg3 {
  display: flex;
  width: 7px;
  height: 35px;
  background: rgba(130, 200, 174, .8);
}
.defaultBg4 {
  display: flex;
  width: 7px;
  height: 35px;
  background: rgba(130, 200, 174, .8);
}
.defaultBg5 {
  display: flex;
  width: 7px;
  height: 35px;
  background: rgba(130, 200, 174, .8);
}
</style>