import Vue from 'vue'
import App from './App.vue'
import router from './router'
import BootstrapVue from 'bootstrap-vue'
import store from './store'

import zh_tran from "../public/lang/transform.js"
Vue.prototype.$zh_tran = zh_tran

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)
VueViewer.setDefaults({
  title: false,
  // toolbar: false
})
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)

Vue.config.productionTip = false
Vue.prototype.BootstrapVue=BootstrapVue;
Vue.use(BootstrapVue);


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
