<template>
<div id="index" class="template7_container">
  <!-- 头部 -->
  <Menu :actived="1" />
  <!-- 头部 -->
    <div class="bannerBox" v-if="thumbShow">
      <div class="viewBtn" @click="handleCloseBanner">
        关闭预览
      </div>
      <div class="viewBtn mt-1" @click="handleShowOrign">
        查看原图
      </div>
      <div class="viewBtn mt-2" @click="handleShowPrev">
        上一张
      </div>
      <div class="viewBtn mt-3" @click="handleShowNext">
        下一张
      </div>
      <div class="viewPic">
        <img :src="thumbLink">
      </div>
      <div class="viewList">
        <div @click.stop="handleChooseShow(item,index)" v-for="(item,index) in banner" :key="index">
          <img :class="currentKey == index ? 'selected' : ''" :src="item + '/applet_suo'">
        </div>
      </div>
    </div>

    <div class="template7_blog_sitecontent">
      <div>
          <div>
              <div class="workListWrapTemplate workListWrapTemplate2">
                  <div class="workContentWrap">
                      <div class="workTile workTile_5"><a>{{info.worksName}}</a></div>
                      <!-- <div class="workCreatedAt">October 16, 2019</div> -->
                      <div class="work_title_border"></div>
                      <!-- <pre class="workContent workContent_5">2019/08/24 GUIYANG<a class="more_title_guide"></a></pre> -->
                  </div>
                  <!-- <div>
                    <a class="workImage_wrap" 
                    v-for="item in banner"
                    v-bind:key = "item.id"
                    >
                      <img :src="item[0]" class="workImage" alt="" />
                          <p class="live_tag_wrap"><i class="icon-video05"></i><span><span>热门</span></span></p>
                      </a>
                    </div> -->


                      <div>
                        <!-- <viewer ref="viewObj" key="work" :preload="true" :images="contentImg"> -->
                          <a class="workImage_wrap workImage_wrap_item" v-for="(item,index) in contentImg" v-bind:key="item.id">
                            <img @click="handleShowThumb(item[0],index)" v-if="item[1] === 'img'" :src="item[0] + '/applet_suo'" :class="'workImage'" alt="" />
                            <video  v-else :src="item[0]" controls controlsList="nodownload" class="workImage"></video>
                          </a>
                        <!-- </viewer> -->
                      </div>


                      <viewer ref="viewObj" key="work" :preload="true" :images="contentImg">
                        <div v-for="(item,index) in contentImg" :key="index">
                          <img v-if="item[1] === 'img'" :src="item[0] + '/applet_size'" style="display:none;" />
                        </div>
                      </viewer>
                      
              </div>
          </div>
      </div>
    </div>

    <!-- 底部 -->
    <Footer :studioName="studioName" :weixin="weixin" :phone="phone" :studioAddress="studioAddress" :banner="footBg"
:wxQrcode="wxcode" />
    <!-- 底部 -->
  </div>
</template>
<script>
import axios from "axios";
import "../../../public/styles/template7.css"
import "../../../public/styles/home.css"

import Menu from "../../components/common/Menu.vue"
import Footer from "../../components/common/Footer.vue"
export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      workList:'',
      goodList:'',
      video:'',
      phone:'',
      weixin:'',
      info:'',
      contentImg:[],
      banner:[],
      footBg:'',
      wxcode:'',
      viewer:{},
      thumbShow:false,
      thumbLink:'',
      currentKey:0
    };
  },
  mounted: function () {
    const id = this.$route.query.id
    this.getStudioInfo(id)
    this.viewer = this.$refs.viewObj.$viewer
    this.viewer.init()
    // this.viewer.options.initialZoom = 3.5
    // this.viewer.options.zoomable = false
      
    window.addEventListener('keydown', (event) => {
      // console.log('event', event)
      if (event.keyCode === 37) {
        // 左箭头
        this.handleShowPrev()
        
      } else if (event.keyCode === 39) {
        // 右箭头
        this.handleShowNext()
      }
      
    })

  },
  methods: {
    handleShowPrev(){
      if(this.currentKey === 0){
        this.currentKey = this.banner.length - 1
      }else{
        this.currentKey--
      }
      this.thumbLink = this.banner[this.currentKey] + '/applet_size'
    },
    handleShowNext(){
      if(this.currentKey === this.banner.length - 1){
        this.currentKey = 0
      }else{
        this.currentKey++
      }
      this.thumbLink = this.banner[this.currentKey] + '/applet_size'
    },
    handleShowOrign(){
      this.thumbShow = false
      this.viewer.view(this.currentKey)
    },
    handleChooseShow(item,index){
      this.currentKey = index
      this.thumbLink = item + '/applet_size'
    },
    handleCloseBanner(){
      this.thumbShow = false
    },
    handleShowThumb(link,index){
      this.handleChooseShow(link)
      this.currentKey = index
      this.thumbShow = true
    },
    showViewPic(key){
      this.viewer.view(key)      
    },
    getStudioInfo(id){
      let self = this
      const domain = document.domain;
      // const domain = 'KuBG.hunyuehui.com'
      const url = "https://api.marryandjoy.com:8443/getstudiobydomain?id=" + domain
      axios.get(url).then(
        function(response) {
          self.logoUrl = response.data.data.logo[0]
          self.bannerList = response.data.data.banner
          self.studioName = response.data.data.name
          self.studioDesc = response.data.data.desc
          self.studioAddress = response.data.data.address
          self.weixin = response.data.data.weixin
          self.studioId = response.data.data.sid
          self.phone = response.data.data.phone

          self.footBg = response.data.data.banner[0][0]
          self.wxcode = response.data.data.wxQrcode

          self.getInfo(self.studioId,id)
        },
        function(res) {
          console.log(res.data);
        }
      )
    },

    getInfo(sid,id){
      let self = this
      const goodurl = "https://api.marryandjoy.com:8443/getappletworkinfo?"+"sid="+sid+'&id='+id
      axios.get(goodurl).then(
        function(response) {
          self.info = response.data.data
          self.contentImg = [...response.data.data.worksCover,...response.data.data.contentImg]
          // self.banner = response.data.data.worksCover
          self.contentImg.forEach(item=>{
            if(item[1] === 'img'){
              self.banner.push(item[0])
            }
          })
        },
        function(res) {
          console.log(res.data);
        }
      );
    }
    

  }
};
</script>
<style>
  .workImage_wrap_item{
    margin-top: 5px;
  }

  .bannerBox{
    position: fixed;
    width:100%;
    height:100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 9999;
    margin:0 auto;
  }
  .bannerBox .viewPic{
    position: absolute;
    left: 0;
    width:80%;
    height:90%;
    /* background: red; */
    margin-left: 20px;
    margin-top:20px;
    margin-bottom:20px;
    text-align: center;
  }
  .bannerBox .viewPic img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .bannerBox .viewList{
    position: absolute;
    right:0;
      width:20%;
      height:100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      overflow-y: scroll;
      align-content: flex-start;
      justify-content: center;
      overflow-y: scroll;
  }
  .bannerBox .viewList div{
    width:100px;
    height:100px;
    border-radius: 12px;
    padding:10px;
  }
  .bannerBox .viewList div img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
    cursor: pointer;
    
    transition: border 0.5s;
  }
  .bannerBox .viewList div img:hover{
    border:4px solid #e8e8e8;
  }
  .bannerBox .viewList div img.selected{
    border:4px solid #e8e8e8;
  }
  .bannerBox .viewBtn{
    position: absolute;
    left:40%;
    bottom:10px;
    width:60px;
    height:30px;
    z-index:1000;
    /* background: #000; */
    border-radius: 12px;
    text-align: center;
    line-height: 28px;
    color:#fff;
    margin:5px;
    font-size:8px;
    border:1px solid #e8e8e8;
    cursor: pointer;
    transition: background-color 1s;
  }
  .bannerBox .viewBtn:hover{
    background: #fff;
    color:#000;
  }
  .bannerBox .viewBtn.mt-1{
    left:calc(40% + 80px);
  }
  .bannerBox .viewBtn.mt-2{
    left:calc(40% + 160px);
  }
  .bannerBox .viewBtn.mt-3{
    left:calc(40% + 240px);
  }
  </style>
  