<template>
    <div>
      <div class="template7_container">
        <div class="template7_nav_wrap">
          <header class="content">
            <div class="user_name" v-if="$store.state !== undefined && $store.state.studioName !== undefined">
              <img
                v-if="$store.state.logoUrl != undefined"
                class="logo"
                :src="$store.state.logoUrl"
                alt="logo" />
                <span>{{ $store.state.studioName }}</span>
            </div>
            <ul v-if="$store.state !== undefined && $store.state.info.menu !== undefined">
              <span :key="index" v-for="(menuItem,index) in $store.state.info.menu">
                <li class="navitem" v-if="menuItem[1] !== '1' && index < 5">
                  <router-link :class="actived === index ? 'nav-a active' :'nav-a'" :to="getRouter(index)">
                    <span v-if="menuItem[0]">{{ menuItem[0] }}</span>
                    <span v-if="!menuItem[0] && index === 0">首页</span>
                    <span v-if="!menuItem[0] && index === 1">作品</span>
                    <span v-if="!menuItem[0] && index === 2">商品</span>
                    <span v-if="!menuItem[0] && index === 3">客样</span>
                    <span v-if="!menuItem[0] && index === 4">品牌</span>
                  </router-link>
                </li>
                <li class="navitem" v-if="menuItem[1] !== '1' && index === 5">
                  <a :class="actived === 5 ? 'nav-a active' :'nav-a'" @click="showCode">
                    <span v-if="menuItem[0]">{{ menuItem[0] }}</span>
                    <span v-else>我的</span>
                  </a>
                </li>
              </span>
              <li class="navitem">
                <a class="nav-a showLangType">
                  <span><img :src='langIcon' style="width:20px;" /></span>
                  <div class="langBox">
                    <li class="navchilditem">
                      <div class="nav-a2" @click="onSwitchLanguage('t')">
                        <span>繁体</span>
                      </div>
                    </li>
                    <li class="navchilditem">
                      <div class="nav-a2" @click="onSwitchLanguage('s')">
                        <span>简体</span>
                      </div>
                    </li>
                  </div>
                </a>
              </li>
              
            </ul>
          </header>
          <div class="account_modal_wrap" v-if="codeShow==1" @click="hideCode">
            <div class="account_modal_wrap_content">
              <div class="account_modal_wrap_wxcode_btn">
                <p class="account_modal_wrap_wxcode_indent">
                  <span>扫码查看「个人中心」</span>
                </p>
                <div class="acount_modal_sanjiao"></div>
              </div>
              <div class="account_modal_wrap_header" style="padding: 30px">
                <img
                  :src="codeUrl"
                  class="account_modal_wrap_header_wxcode_img" />
                <p class="account_modal_wrap_header_wxcode_title">
                  <span>微信扫码登录小程序</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import axios from "axios";
import { 
  // mapGetters, 
  mapActions
 } from 'vuex'
    export default {
        name:"Menu",
        props:{
          actived:{
            type:Number,
            default:0
          }
        },
        mounted(){
          let self = this
          self.GetStudioInfo()
          self.show = true
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
          // window.addEventListener("scroll", self.scrolling)
        },
        created(){
          
        },
        data() {
            return {
              codeShow:0,
              show:false,
              codeUrl:'',
              sid:'',
              langIcon: require('../../../public/lang/lang.png')
            };
        },
        methods: {
          onSwitchLanguage (val) {
            this.lang = val
            if (val === 's') {   //简体
              localStorage.setItem('lang', 's')

            } else {     //繁体
              localStorage.setItem('lang', 't')
            }
            this.$zh_tran(localStorage.getItem('lang'))
          },
          ...mapActions(['GetStudioInfo']),
          initMenu(){
            
          },
          showCode () {
            this.codeShow = 1
            const self = this
            self.GetStudioInfo()
            self.sid = self.$store.state.sid

            const url = "https://api.marryandjoy.com:8443/websiteappletcode?sid="+self.sid
            axios.get(url).then(
                function(response) {
                  self.codeUrl = response.data.data.imageBuffer
                },
                function(res) {
                    console.log(res.data);
                }
            )
          },
          hideCode () {
            this.codeShow = 0
          },
          scrolling(){
            // console.log('scoll::::')
          },
          getRouter (index = 0){
            const tpl = this.$store.state.tpl
            const tplTheme = [6,7]
            let router = '/'
            switch(index){
              case 1: router += tplTheme.indexOf(tpl) >= 0 ? 'black/workList' : 'workList'; break;
              case 2: router += tplTheme.indexOf(tpl) >= 0 ? 'black/goodList' : 'goodList'; break;
              case 3: router += tplTheme.indexOf(tpl) >= 0 ? 'black/caselist' : 'caselist'; break;
              case 4: router += 'about'; break;
              case 5: router += ''; break;
            }

            return router
          }
        }
    }
</script>

<style>

.navitem{
  cursor:pointer;
  height:70px;
}
.langBox{
  display:none;
  position: fixed;
  width: 80px;
  background: #212121;
  color: #fff;
}
.showLangType:hover .langBox { 
  display:flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.langBox .navchilditem{
  padding:0;
  width:80px;
  text-align: center;
}
.langBox .navchilditem:hover{
  background: #494747;
}
</style>