<template>
  <div id="index" class="template7_container">
    <!-- 头部 -->
    <Menu :actived="4" />
    <!-- 头部 -->

    <div class="template7_blog_sitecontent" v-if="contentList.length > 0">
      <div>
          <div>
              <div class="workListWrapTemplate workListWrapTemplate2">
                <div>
                    <div class="workImage_wrap workImage_wrap_item" v-for="item in contentList" v-bind:key="item.id">
                      <img v-if="item[1] === 'img'" :src="item[0]" class="workImage" alt="" />
                      <video  v-else :src="item[0]" controls controlsList="nodownload" class="workImage"></video>
                    </div>
                </div>
              </div>
          </div>
      </div>
    </div>

    

      

    <div class="template7_about_sitecontent">
        <div class="maxWrap center about_container">
            <div class="photographerAvatar photographerAvatar_5" >
              <img :src="logoUrl" style="width:100%;" />
            </div>
            <div class="center photographerName photographerName_5" style="font-size:20px;">{{studioName}}</div>
            <!-- <div class="center photographerLocation" style="color:#CFBB90">记录时光中的最美瞬间</div> -->
            <div class="center photographerLocation">微信 {{weixin}}</div>
            <div>
                <div class="aboutDescription center aboutDescription_5" style="margin-top:10px;">
                    <div class="left"><i class="icon-yinhao02 yinIcon"></i></div>
                    <div class="workDes workDes_5" style="font-size:18px;">{{studioDesc}}</div>
                    <div class="about_des_imgs"></div>
                    <div class="right"><i class="icon-yinhao01 yinIcon"></i></div>
                </div>
            </div>
            <div class="contactUs contactUs_5"><span>联系我</span></div><span></span>
            <div class="workCoverWrap" style="display:flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;align-items: center;">
              <div v-for="item in workList" v-bind:key = "item.id"  style="width:33.33%;height:300px;overflow: hidden;">
                <router-link :to="{path:'/workInfo',query: {id: item['worksId']}}">
                  <!-- <a class="aboutWorkCover" :style="{backgroundImage:'url('+item.worksCover[0][0]+')'}"></a> -->
                  <img class="aboutWorkCover img-fluid" style=":" :src="`${item.worksCover[0][0]}/applet_suo`" alt="">
                </router-link>
              </div>
            </div>
        </div>
    </div>

      

      <!-- 底部 -->
      <Footer :studioName="studioName" :weixin="weixin" :phone="phone" :studioAddress="studioAddress" :banner="footBg" :wxQrcode="wxcode" />
      <!-- 底部 -->

  </div>
</template>
<script>
import axios from "axios";
import "../../../public/styles/template7.css"
import "../../../public/styles/home.css"
import Menu from "../../components/common/Menu.vue"
import Footer from "../../components/common/Footer.vue"
export default {
  components: {
    Menu,
    Footer
  },
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      workList:'',
      goodList:'',
      video:'',
      phone:'',
      weixin:'',
      wxcode:'',
      footBg:'',
      contentList:[]
    };
  },
  mounted: function () {
    this.getStudioInfo()

  },
  methods: {
    getStudioInfo(){
      let self = this
      const domain = document.domain;
      const url = "https://api.marryandjoy.com:8443/getstudiobydomain?id=" + domain
      axios.get(url).then(
        function(response) {
          console.log('response.data.dat:::', response.data.data)
          self.logoUrl = response.data.data.logo[0]
          console.log(self.logoUrl)
          self.bannerList = response.data.data.banner
          self.studioName = response.data.data.name
          self.studioDesc = response.data.data.desc
          self.studioAddress = response.data.data.address
          self.weixin = response.data.data.weixin
          self.studioId = response.data.data.sid
          self.phone = response.data.data.phone

          self.wxcode = response.data.data.wxQrcode
          self.footBg = response.data.data.banner[0][0]
          self.contentList = response.data.data.appletDesc

          self.getWorkList(self.studioId)
          // self.getGoodList(self.studioId)
        },
        function(res) {
          console.log(res.data);
        }
      )
    },

    getWorkList(sid){
      let self = this
      const workurl = "https://api.marryandjoy.com:8443/getappletworks?"+"sid="+sid+'&show=1&perPage=10&page=1'
      axios.get(workurl).then(
        function(response) {
          console.log(response);
          self.workList = response.data.data
        },
        function(res) {
          console.log(res.data);
        }
      );
    },

   


  }
};
</script>
<style>
  #carousel-fade{
    width: 100%;
    height: 46.875vw;
    position: fixed;
    overflow: hidden;
    margin-top: 70px;
  }
  .nav-a{
    text-decoration: none;
  }
  .studioDesc{
    text-decoration: none;
  }
  </style>
  