<template>
    <div>
        <div class="footer_container">
        <div
          class="footer_content"
          :style="'background:url( &quot;' + banner +'&quot;) center 59px/cover fixed' ">
          <div class="footer_content_copy bg_white"></div>
          <div class="footer_content_wrap">
            <div class="footer_item footer_item1">
              <p class="fir_title" style="font-weight:900;color:#fff;font-size:22px;"><span>联系我们</span></p>
              <p class="sub_title">Contact Us</p>
            </div>
            
            <div class="footer_item footer_item2">
              <p class="title" style="font-weight:900;color:#fff;font-size:22px;">{{studioName}}</p>
              <div class="detial_info_wrap">
                <p class="detial_info" style="display: flex;"><span style="width:50px;">微信：</span>{{weixin}}</p>
                <p class="detial_info" style="display: flex;"><span style="width:50px;">电话：</span>{{ phone }}</p>
                <p class="detial_info" style="display: flex;">
                  <span style="width:50px;">地址：</span>
                  <span v-if="addressList.length == 0" style="white-space: pre-line;width:300px;word-wrap:break-word; word-break:break-all; overflow: hidden;">
                    {{studioAddress}}
                  </span>
                  <span v-if="addressList.length > 0" style="width:300px;display:flex;flex-direction: column;">
                    <span  :key="index" v-for="(addr,index) in addressList"  style="white-space: pre-line;width:300px;word-wrap:break-word; word-break:break-all; overflow: hidden;">{{ addr.studio_name }}：{{ addr.studio_address }}{{ addr.studio_phone != null ? ' （'+addr.studio_phone+'）' : '' }}</span>
                  </span>
                </p>
              </div>
            </div>

            <div class="footer_item footer_item3">
              <p class="title" style="font-weight:900;color:#fff;font-size:22px;"><span>关注</span></p>
              <div>
                <img :src="wxQrcode" alt="" width="100px" />
                <p style="width:100px;text-align:center;padding:10px 0;"><span>[微信扫码关注]</span></p>
              </div>
              <div style="display:none;">
                <div class="icon_wrap">
                  <i class="icon-weibo03 weiboBtn"></i>
                </div>
                <div class="icon_wrap">
                  <i class="icon-wechat03 wechatBtn"></i>
                </div>
              </div>
            </div>

            <!-- <div class="footer_item pos_center">
              <div>
                <img
                  src="https://img.picbling.cn/FndnlZAdP9S6aEckasbOIqDqyvVb"
                  alt=""
                  width="100px" />
                <p><span>微信二维码</span></p>
              </div>
            </div> -->
          </div>
        </div>


        <div
          class="footer_bar"
          style="
            display: flex;
            justify-content: center;
            align-items: center;
          ">
          <p class="footer_bar_item">
            COPYRIGHT (©)
            {{studioName}}
          </p>
          <span class="beian_text"
            ><a
              href="https://beian.miit.gov.cn"
              class="beian_number"
              target="_blank"
              ><span></span></a
          ></span>
          <p class="footer_bar_item">
            <a href="https://saascloud.marryandjoy.com/" target="_blank">
              <span>婚悦科技提供技术支持</span>
            </a>
          </p>
        </div>

        
      </div>
    </div>
</template>
<script>
    export default {
        name:"Footer",
        props:{
          studioName:String,
          weixin:String,
          phone:String,
          studioAddress:String,
          addressList:Array,
          banner:String,
          wxQrcode:String,
        },
        mounted(){
        },
        data() {
            return {
            };
        },
        methods: {
        }
    }
</script>

<style>
  .footer_bar_item a{
    text-decoration: none!important;
    color: rgba(255,255,255,.6);
  }
</style>