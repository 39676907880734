<template>
  <div id="index" class="template7_container" style="background:#000;color:#fff;">
    <!-- 头部 -->
    <Menu :actived="2" />
    <!-- 头部 -->

    

      

  <div class="template7_blog_sitecontent">
      <div class="series_list_header_wrap">
      
        <div class="header_wrap" v-if="tablist.length > 0">
          <div class="classItemBox">
            <div @click="tabSel({id:0})" class="classItem">
              全部
            </div>
            <div class="" @click="tabSel(item)" v-for="item in tablist" v-bind:key="item.id">
              <div :class="param.class === item.id ? 'classItem actived' : 'classItem '">
                {{item.name}}
              </div>
            </div>
          </div>
          <div class="classLabelBox" v-if="showLabel === 1">
            <div class="labelItem" @click="showLabelChild(0)">
              不限
            </div>
            <div @click="showLabelChild(item.id)" :class="currentLabel === item.id ? 'labelItem actived' : 'labelItem '"
              :key="index" v-for="(item,index) in label">
              {{item.name}}
            </div>
          </div>
          <div class="classLabelBox" v-if="showChild">
            <div class="labelItem" @click="reloadDataList(0)">
              不限
            </div>
            <div :class="param.label === item.id ? 'labelItem actived' : 'labelItem'" @click="reloadDataList(item.id)"
              :key="index" v-for="(item,index) in labelchild">
              {{ item.name}}
            </div>
          </div>
        </div>
      </div>

      <div>
          <div>
              <div class="workListWrapTemplate workListWrapTemplate2" style="padding-bottom:20px;" v-for="item in workList" v-bind:key = "item.id">
                  
                  <router-link target="_blank" class="router" :to="{path:'/black/goodInfo',query: {id: item.id}}">
                  <div style="height:550px;overflow: hidden;">
                    <a class="workImage_wrap">
                      <img v-if="item.img !== ''" :src="item.img[0][0]" class="workImage img-fluid"  alt="" />
                          <p class="live_tag_wrap"><i class="icon-video05"></i><span><span>热销套系</span></span>
                          </p>
                      </a>
                    </div>
                  </router-link>
                  <div class="workContentWrap">
                    <div class="workTile workTile_5" style="color:#fff;margin-top:20px;"><a>{{item.name}}</a></div>
                    <!-- <div class="workCreatedAt">October 16, 2022</div>
                    <div class="work_title_border"></div> -->
                    <pre class="workContent workContent_5" style="font-size:20px;margin:0;">价格：{{item.price}}</pre>
                  </div>
                  <!-- <div class="workOperateWrap flexBetween"><span></span>
                      <div>
                          <div class="workShareList workShareList_5"><i class="icon-share01 onShareBtn"></i><span><span>分享</span></span></div>
                          <div class="workMoreList workMoreList_5"><a><i class="icon-more01 onShareBtn"></i><span>更多</span></a></div>
                      </div>
                  </div> -->
              </div>
          
          </div>
          <div class="more_btn3 blackTheme" v-if="workList.length === 0">暂无相关内容</div>
          <div class="more_btn3 blackTheme" @click="nextLoadList" v-if="workList.length > 0">{{nextTxt}}</div>
      </div>
  </div>

      

      <!-- 底部 -->
      <Footer :studioName="studioName" :weixin="weixin" :phone="phone" :studioAddress="studioAddress" :banner="footBg"
:wxQrcode="wxcode" />
      <!-- 底部 -->

  </div>
</template>
<script>
import axios from "axios";
import "../../../../public/styles/template7.css"
import "../../../../public/styles/home.css"
import Menu from "@/components/common/Menu.vue"
import Footer from "@/components/common/Footer.vue"
export default {
  components:{
    Menu,
    Footer
  },
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      workList:[],
      goodList:'',
      video: '',
      phone: '',
      weixin:'',
      work1:[],
      work2:[],
      work3:[],
      work4:[],
      work5:[],
      work6:[],
      work7:[],
      work8:[],
      work9:[],
      work10:[],
      work0:[],
      wxcode:'',
      footBg: '',
      themeType: 0,
      //分类
      tablist: [],
      currentIndex: 0,
      hasCurKey: false,
      showChild: false,
      showLabel: 0,
      labelList: [],
      studioId: 0,
      param: {
        page: 1,
        perPage: 12,
        class: 0,
        label: 0,
        show: 1,
        sid: 0
      },
      classParam: {},
      nextTxt: '点击加载更多',
      currentLabel:0
    };
  },
  mounted: function () {
    if (this.$route.query.class !== undefined) {
      this.param.class = Number(this.$route.query.class)
    }
    this.getStudioInfo()

  },
  methods: {
    getStudioInfo(){
      let self = this
    const domain = document.domain;
      // const domain = 'KuBG.hunyuehui.com'
      const url = "https://api.marryandjoy.com:8443/getstudiobydomain?id=" + domain
      axios.get(url).then(
        function(response) {
          self.logoUrl = response.data.data.logo[0]
          self.bannerList = response.data.data.banner
          self.studioName = response.data.data.name
          self.studioDesc = response.data.data.desc
          self.studioAddress = response.data.data.address
          self.weixin = response.data.data.weixin
          self.studioId = response.data.data.sid
          self.phone = response.data.data.phone

          self.footBg = response.data.data.banner[0][0]
          self.wxcode = response.data.data.wxQrcode

          self.getTablist()
          self.getDataList()
        },
        function(res) {
          console.log(res.data);
        }
      )
    },



    getTablist () {
      let self = this
      const url = "https://api.marryandjoy.com:8443/getappletclass"

      this.classParam.sid = this.studioId
      this.classParam.type = 2
      this.classParam.pageNo = 1
      this.classParam.pageSize = 50

      axios.get(url, {
        params: this.classParam
      }).then(
        function (response) {
          console.log(response);
          if (response.data.data.total > 0){
            self.tablist = response.data.data.list
          }
          
        },
        function (res) {
          console.log(res.data);
        }
      );
    },

    getDataList () {
      let self = this
      this.param.show = 1
      this.param.sid = this.studioId
      const workurl = "https://api.marryandjoy.com:8443/getgoodslistapplet"
      axios.get(workurl, {
        params: this.param
      }).then(
        function (response) {
          console.log(response);
          response.data.data.forEach(item => {
            if (item.name !== 'default' && item.img !== '' && item.img.length > 0) {
              self.workList.push(item);
            }

          })
          if (response.data.data.length < self.param.perPage) {
            self.nextTxt = '没有更多内容了'
          } else {
            self.nextTxt = '点击加载更多'
          }

        },
        function (res) {
          console.log(res.data);
        }
      );
    },
    tabSel (item) {
      this.workList = []
      this.param.page = 1;
      this.param.class = item.id;
      this.showLabelList(item.id);
      this.reloadList();
    },
    reloadDataList (label) {
      this.workList = []
      this.param.page = 1;
      this.param.label = label;
      this.reloadList();
    },
    reloadList () {
      this.getDataList()
    },
    nextLoadList () {

      this.param.page = Number(this.param.page) + 1;
      this.getDataList()
    },
    showLabelList (class_id = 0) {
      if (class_id > 0) {
        this.getLabel(class_id);
        this.showLabel = 1;
      } else {
        this.showChild = false;
        this.showLabel = 0;
        this.currentLabel = 0;
        this.param.label = 0;
      }

    },
    getLabel (class_id) {
      this.workList = []
      if (this.classParam.class > 0 && class_id != this.classParam.class) {
        this.showChild = false;
        this.showLabel = 0;
        this.currentLabel = 0;
        this.param.label = 0;
      }
      this.classParam.sid = this.studioId;
      this.classParam.type = 2;
      this.classParam.class = class_id;
      this.classParam.pageNo = 1;
      this.classParam.pageSize = 50;
      this.label = [];
      this.labelchild = [];

      let self = this
      const workurl = "https://api.marryandjoy.com:8443/getlabellist"
      axios.get(workurl, {
        params: this.classParam
      }).then(
        function (response) {
          console.log('labelList::', response.data.data);
          // self.workList = response.data.data
          if (response.data.data.total != 0) {

            if (response.data.data.list.length > 1) {
              self.showLabel = 1
              self.showChild = false
            } else if (response.data.data.list.length == 1) {
              self.showLabel = 0
              self.showChild = true
              self.labelchild = response.data.data.list[0].children;
            } else {
              self.showLabel = 0
              self.showChild = false
            }
            response.data.data.list.forEach(item => {
              self.label.push(item)
            });
          }
        },
        function (res) {
          console.log(res.data);
        }
      );

    },
    showLabelChild (pid) {
      this.currentLabel = pid;
      let demoArr = [];
      this.label.forEach(function (item) {
        if (item.id == pid) {
          demoArr = item.children;
        }
      })
      this.labelchild = demoArr;
      this.showChild = true;
    }


  }
};
</script>
<style>
  #carousel-fade{
    width: 100%;
    height: 46.875vw;
    position: fixed;
    overflow: hidden;
    margin-top: 70px;
  }
  .nav-a{
    text-decoration: none;
  }
  .studioDesc{
    text-decoration: none;
  }
  </style>
  