<template>
  <div class="carousel">
    <div class="carousel-wrapper" v-if="mode === 0">
      <div
        v-for="(slide, index) in bannerList"
        :key="index"
        :class="{ 'active-slide': index === currentSlide }"
      >
        <img :src="`${slide[0]}`" alt="Slide" />
      </div>
      <button class="prevSlide icon-button" @click="prevSlide">
        <i class="left-icon">&#x2190;</i>
      </button>
      <button class="nextSlide icon-button" @click="nextSlide">
        <i class="right-icon">&#x2192;</i>
      </button>
    </div>

    <div  v-if="mode === 1" id="carousel-fade" class="" style="width:100%;height:calc(42.875vw);overflow-x: hidden;">
        <div id="imgBox1" class="" style="width:20000px;height:calc(42.875vw);">
          <div class="" style="float:left;overflow: hidden;height:calc(42.875vw);" :key="index"
            v-for="(item,index) in bannerList">
            <img :src="item[0]" class="img-fluid" style="height: 42.875vw;" />
          </div>
          <div class="" style="float:left;overflow: hidden;height:calc(42.875vw);" :key="index+'_'"
            v-for="(item,index) in moreBanner">
            <img :src="item[0]" class="img-fluid" style="height: 42.875vw;" />
          </div>
        </div>
        <div id="imgBox2">
        </div>
      </div>

    <div class="carousel-wrapper" v-if="mode === 2">
      <div v-if="mode === 2" id="carousel-fade" class="" style="display:flex;">
          <div class="" style="overflow: hidden;height:calc(42.875vw);" :key="index" v-for="(item,index) in bannerList">
            <img :src="item[0]" class="img-fluid" style="height: 42.875vw;" />
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name:"Carousel",
  props:{
    bannerList:{
      type:Array,
      default:()=>[]
    },
    mode:{
      type:Number,
      default:0
    }
  },
  data() {
    return {
      currentSlide: 0,
      leftEvent: '',
      rightEvent: '',
      moreBanner:[]
    };
  },
  methods: {
    nextSlide() {
      this.currentSlide = (this.currentSlide + 1) % this.bannerList.length;
    },
    prevSlide() {
      this.currentSlide =
        (this.currentSlide - 1 + this.bannerList.length) % this.bannerList.length;
    },
    init(){
      clearInterval(this.leftEvent)
      if (this.bannerList.length <= 4){
        this.moreBanner = this.bannerList
      }
      
      setTimeout(() => {
        if (this.mode === 1) {
          console.log('开始滚动')
          this.moveToLeft()
        }
        
      }, 1000)
      
    },
    moveToLeft () {

      var FGgoleft = document.getElementById('carousel-fade')
      var FGgoleft1 = document.getElementById('imgBox1')
      var FGgoleft2 = document.getElementById('imgBox2')

      this.leftEvent = setInterval(function(){

        // console.log(FGgoleft2.offsetWidth, FGgoleft.scrollLeft, FGgoleft1.offsetWidth)
        if (FGgoleft2.offsetWidth - FGgoleft.scrollLeft <= 0) {
          FGgoleft.scrollLeft -= FGgoleft1.offsetWidth
        }  else {
          FGgoleft.scrollLeft++
        }
      },20)
    },
  },
  mounted() {
    // 自动轮播
    setInterval(this.nextSlide, 4000); // 每3秒切换一次轮播项

    if(this.mode==1){
      this.init()
    }
  }
};
</script>

<style scoped>
.carousel {
  position: relative;
  width: 100%;
  height: 46.875vw;
  margin: 0 auto;
}

.carousel-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
  /* width: 100%;
  height: 46.875vw; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.active-slide {
  flex: 0 0 100%;
}

img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: cover; 
  object-position: center; 
}

button {
  cursor: pointer;
}
.prevSlide{
  position: fixed;
  left: 43px;
  top: 26.875vw;
}
.nextSlide{
  position: fixed;
  right: 43px;
  top: 26.875vw;
}

.icon-button {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #acb6bc;
  opacity: 0.5;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.left-icon,
.right-icon {
  font-size: 20px;
  margin: 0 5px;
}
</style>
