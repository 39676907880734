<template>
  <div>
    <!-- 头部 -->
    <Menu :actived="0" />
    <!-- 头部 -->

    <!-- 轮播图 -->
    <Carousel 
        v-if="bannerList.length > 0" 
        :bannerList="bannerList" 
        :mode="bannerMode"></Carousel>
    <!-- 轮播图 -->

    <div class="template7_inner_container">
      <!-- 分类 -->
      <div class="template7_index_waterfall_wrap">
      
        <Title settitle="作品分类" themeType="3"  tourl="/workList"/>

        <div class="waterfall_cover_wrap" style="padding:0;">
          <div class="waterfall_cover_box_5" v-for="item in typeList" v-bind:key="item.id" style="
                      margin:5px;
                      position: relative;
                      background-color: #f0f0f0;
                      cursor: pointer;
                      display: inline-block;
                      -webkit-flex-grow: 299.8535871156662;
                      opacity: 1;
                      height: 350px;
                      width:40%!important;
                      overflow: hidden;
                    ">
            <i style="display: block; padding-bottom: 66.69921875%"></i><img style="
                        position: absolute;
                        top: 0;
                        width: 100%;
                        vertical-align: bottom;
                      " class="img-fluid" :src="item.img + '/applet_suo'" alt="loading" />
            <router-link :to="`/worklist?class=`+item.id">
              <div class="hoverBg3"></div>
            </router-link>
            <div class="vcenter">
              <div>{{item.name}}</div>
              <!-- <div>Japan</div> -->
            </div>
          </div>
        </div>

        

      </div>
      <!-- 分类 -->

      <!-- 案例 -->
      <Title settitle="作品欣赏" themeType="3" tourl="/workList" />

      <div class="waterfall_cover_wrap" style="padding:0 0 40px 0;">
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    height:600px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work0.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work0['worksId']}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i><img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work0['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
      
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work1.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work1['worksId']}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i><img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work1['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work2.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work2['worksId']}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work2['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work3.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work3['worksId']}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work3['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    height:600px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work4.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work4['worksId']}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work4['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 300.2932551319648px;
                    -webkit-flex-grow: 300.2932551319648;
                    flex-grow: 300.2932551319648;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work5.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work5['worksId']}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work5['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 299.8535871156662px;
                    height:600px;
                    -webkit-flex-grow: 299.8535871156662;
                    flex-grow: 299.8535871156662;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work6.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work6['worksId']}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work6['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 199.92190550566184px;
                    -webkit-flex-grow: 199.92190550566184;
                    flex-grow: 199.92190550566184;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work7.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work7['worksId']}}">
            <i style="
                    display: block;
                    padding-bottom: 100.03906250000001%;
                  "></i>
            <img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work7['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
        <div class="waterfall_cover_box_5" style="
                    margin: 2px;
                    position: relative;
                    cursor: pointer;
                    display: inline-block;
                    width: 300.5135730007337px;
                    -webkit-flex-grow: 300.5135730007337;
                    flex-grow: 300.5135730007337;
                    opacity: 1;
                    overflow: hidden;
                  " v-if="this.work8.length!=0">
          <router-link target="_blank" class="router" :to="{path:'/workInfo',query: {id: this.work8['worksId']}}">
            <i style="display: block; padding-bottom: 66.69921875%"></i>
            <img style="
                      position: absolute;
                      top: 0;
                      width: 100%;
                      vertical-align: bottom;
                    " class="img-fluid" :src="`${this.work8['worksCover'][0][0]}/applet_suo`" alt="loading" />
          </router-link>
        </div>
      
           
      
      </div>
      
      <router-link class="more_btn2" to="/workList">MORE</router-link>
      
      <!-- 案例 -->


      <!-- 服务套餐 -->
      <div class="template7_index_waterfall_wrap" v-if="goodShow">
        
        <Title settitle="服务套餐" themeType="3" tourl="/goodList"/>



        <div class="work_list_wrap fadeIn animation2">
          <div class="work_list_content" style="transform: translateX(0)">

            <div class="work_item_wrap"
            v-for="item in goodList"
            v-bind:key = "item.id"
            >

            
              <div class="work_item" v-if="item.img != ''">
                <a>
                  <div style="width: 100%; overflow: hidden">
                      <div class="cover_url_item">
                        <router-link target="_blank" class="router" :to="{path:'/goodInfo',query: {id: item.id}}">
                          <img :src="item.img[0][0] + '/applet_suo'" class="img-fluid" alt=""/>
                        </router-link>
                      </div>
                  </div>
                  <div class="title ellipsis">
                    {{item.name}}
                  </div>
                  <div class="sub_title">{{item.price}}</div>
                </a>
                <p class="live_tag_wrap">
                  <i class="icon-video05"></i
                  ><span><span>热门套餐</span></span>
                </p>
              </div>

            </div>

            
          </div>
        </div>

        <div class="">
          <router-link class="more_btn" to="/goodList">MORE</router-link>
        </div>
        
      </div>
      <!-- 服务套餐 -->

      <!-- 简介 -->
      <div class="template7_description_wrap">
          <router-link class="studioDesc" to="/about">
            <div class="description_wrap">
              <div
                class="avatar_url"
                >
                <img :src="logoUrl" class="img-fluid" alt="">
                </div>
              <div class="header_des">
                <div class="title">{{studioName}}</div>
                <div class="sub_title">About Us</div>
                <div class="description_content">
                  <p>
                    {{studioDesc}}
                  </p>
                </div>
              </div>
            </div>
          </router-link>
        </div>
        <!-- 简介 -->

      <!-- 视频 -->
      <div class="template7_index_waterfall_wrap template8_video_index_container" v-if="video">
        <!-- <div class="des_header">
          <p class="title"><span>VIDEO</span></p>
          <p class="sub_title">视频展示</p>
        </div> -->
        <Title settitle="视频展示" themeType="3" />

        <div class="video_wrap" style="overflow:hidden;background:#000">
            <div class="video_is_pfop" style="height:600px">
              <video :src="video" style="width:100%;height:600px;" controls controlsList="nodownload" autoplay loop></video>
            </div>
        </div>
      </div>
      <!-- 视频 -->

      

      <!-- 底部 -->
      <Footer
      :studioName="this.studioName"
      :weixin="this.weixin"
      :phone="phone"
      :studioAddress="this.studioAddress"
      :addressList="studioAddressList"
      :banner="footBg"
      :wxQrcode="wxcode"
      />
      <!-- 底部 -->

    </div>
  </div>
</template>
<script>
import axios from "axios";
import "../../public/styles/template7.css"
import "../../public/styles/home.css"
import Title from "./common/Title.vue"
import Footer from "../components/common/Footer"
import Menu from "../components/common/Menu"
import Carousel from "../components/common/Carousel"
export default {
  name:"Home",
  data() {
    return {
      logoUrl:'',
      bannerList:[],
      studioName:'',
      studioDesc:'',
      studioAddress:'',
      studioAddressList:[],
      workList:'',
      goodList:'',
      video:'',
      weixin:'',
      phone: '',
      work0:[],
      work1:[],
      work2:[],
      work3:[],
      work4:[],
      work5:[],
      work6:[],
      work7:[],
      work8:[],
      work9:[],
      work10:[],
      codeShow:0,
      typeList:[],
      footBg:'',
      wxcode:'',
      bannerMode:0,
      goodShow:''
    };
  },
  components:{
    Title,
    Menu,
    Footer,
    Carousel
  },
  mounted: function () {
    this.getStudioInfo()
    this.goodShow = this.$store.state.info.menu[2][0]
  },
  methods: {
    getStudioInfo(){
      let self = this
      const domain = document.domain;
      // const domain = 'KuBG.hunyuehui.com'
      const url = "https://api.marryandjoy.com:8443/getstudiobydomain?id=" + domain
      axios.get(url).then(
        function(response) {
          self.logoUrl = response.data.data.logo[0]
          self.bannerList = response.data.data.banner
          self.footBg = self.bannerList[0][0]
          self.studioName = response.data.data.name
          self.studioDesc = response.data.data.desc
          self.studioAddress = response.data.data.address
          self.studioAddressList = response.data.data.addressList
          self.weixin = response.data.data.weixin
          self.phone = response.data.data.phone
          self.studioId = response.data.data.sid
          self.video = response.data.data.video
          self.wxcode = response.data.data.wxQrcode
          self.bannerMode = Number(response.data.data.bannerType)

          self.getTypeList(self.studioId)
          self.getWorkList(self.studioId)
          self.getGoodList(self.studioId)
        },
        function(res) {
          console.log(res.data);
        }
      )
    },

    getWorkList(sid){
      let self = this
      const workurl = "https://api.marryandjoy.com:8443/getappletworks?"+"sid="+sid+'&show=1&perPage=12&page=1'
      axios.get(workurl).then(
        function(response) {
          console.log(response);
          self.workList = response.data.data
          self.work0 = self.workList[0]!=undefined ? self.workList[0]: []
          self.work1 = self.workList[1]!=undefined ? self.workList[1]: []
          self.work2 = self.workList[2]!=undefined ? self.workList[2]: []
          self.work3 = self.workList[3]!=undefined ? self.workList[3]: []
          self.work4 = self.workList[4]!=undefined ? self.workList[4]: []
          self.work5 = self.workList[5]!=undefined ? self.workList[5]: []
          self.work6 = self.workList[6]!=undefined ? self.workList[6]: []
          self.work7 = self.workList[7]!=undefined ? self.workList[7]: []
          self.work8 = self.workList[8]!=undefined ? self.workList[8]: []
          self.work9 = self.workList[9]!=undefined ? self.workList[9]: []
          self.work10 = self.workList[10]!=undefined ? self.workList[10]: []
        },
        function(res) {
          console.log(res.data);
        }
      );
    },

    getGoodList(sid){
      let self = this
      const goodurl = "https://api.marryandjoy.com:8443/getgoodslistapplet?"+"sid="+sid+'&status=1&perPage=10&page=1'
      axios.get(goodurl).then(
        function(response) {
          console.log(response);
          self.goodList = response.data.data
        },
        function(res) {
          console.log(res.data);
        }
      );
    },

    showCode(){
      this.codeShow=1
    },
    hideCode(){
      this.codeShow=0
    },
    getTypeList(sid){
      let self = this
      const goodurl = "https://api.marryandjoy.com:8443/getappletclass?"+"sid="+sid+'&type=1&pageSize=6&pageNo=1'
      axios.get(goodurl).then(
        function(response) {
          console.log(response);
          self.typeList = response.data.data.list
        },
        function(res) {
          console.log(res.data);
        }
      );
    }


  }
};
</script>
<style>
#carousel-fade{
  width: 100%;
  height: 46.875vw;
  position: fixed;
  overflow: hidden;
  margin-top: 70px;
}
.nav-a{
  text-decoration: none;
}
.studioDesc{
  text-decoration: none;
}
.more_btn{
  text-decoration: none;
}
.more_btn2{
    width: 218px;
    height: 44px;
    line-height: 44px;
    font-size: 15px;
    color: #bdbdbd;
    margin: 0 auto 60px;
    text-align: center;
    border: 1px solid #dfdfdf;
    display: block;
    transition: all 0.3s;
    text-decoration: none;
}
.more_btn2:hover{
    color: #fff;
    background:rgba(130, 200, 174, .8) !important;
}
a{
  text-decoration: none !important;
}
</style>
