import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logoUrl: '',
    weixin: '',
    studioName: '',
    sid: '',
    studioAddress:'',
    domain:'',
    tpl:'1',
    info:[]
  },
  mutations: {
    SET_LOGO: (state, logoUrl) => {
    state.logoUrl = logoUrl
    },
    SET_WEIXIN: (state, weixin) => {
    state.weixin = weixin
    },
    SET_STUDIONAME: (state, studioName) => {
    state.studioName = studioName
    },
    SET_SID: (state, sid) => {
    state.sid = sid
    },
    SET_ADDRESS: (state, studioAddress) => {
    state.studioAddress = studioAddress
    },
    SET_DOMAIN: (state, domain) => {
    state.domain = domain
    },
    SET_TPL: (state, tpl) => {
    state.tpl = tpl
    },
    SET_INFO: (state, info) => {
    state.info = info
    },
  },
  actions: {
    GetStudioInfo({commit}){
    const domain = document.domain;
    // const domain = 'KuBG.hunyuehui.com'
    const url = "https://api.marryandjoy.com:8443/getstudiobydomain?id=" + domain
      axios.get(url).then(
            function(response) {
                commit('SET_LOGO', response.data.data.logo[0])
                commit('SET_WEIXIN', response.data.data.weixin)
                commit('SET_STUDIONAME', response.data.data.name)
                commit('SET_SID', response.data.data.sid)
                commit('SET_ADDRESS', response.data.data.address)
                commit('SET_TPL', response.data.data.tpl)
                commit('SET_INFO', response.data.data)
                document.title = response.data.data.name + ' | ' + document.title
            },
            function(res) {
                console.log(res.data);
            }
        )
    }
  },
})


