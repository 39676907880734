<template>
  <div>
      <Home v-if="tpl==1" />
      <Home2 v-if="tpl==2" />
      <Home3 v-if="tpl==3" />
      <Home4 v-if="tpl==4" />
      <Home5 v-if="tpl==5" />
      <Home6 v-if="tpl==6" />
      <Home7 v-if="tpl==7" />
      <Home8 v-if="tpl==8" />
      <Home4 v-if="tpl==9" />
  </div>
</template>

<script>
import Home from "../components/Home.vue"
import Home2 from "../components/Home2.vue"
import Home3 from "../components/Home3.vue"
import Home4 from "../components/Home4.vue"
import Home5 from "../components/Home5.vue"
import Home6 from "../components/Home6.vue"
import Home7 from "../components/Home7.vue"
import Home8 from "../components/Home8.vue"
import axios from 'axios'

export default {
data() {
  return {
    tpl:"",
  };
},
components:{
    Home,
    Home2,
    Home3,
    Home4,
    Home5,
    Home6,
    Home7,
    Home8
},
created: function () {
 this.getStudioInfo()
},
methods: {
  getStudioInfo(){
    let self = this
      const domain = document.domain;
      // const domain = 'KuBG.hunyuehui.com'
  const url = "https://api.marryandjoy.com:8443/getstudiobydomain?id=" + domain
  axios.get(url).then(
    function(response) {
      self.tpl = response.data.data.tpl
    },
    function(res) {
      console.log(res.data);
    }
  )
},
  
}
};
</script>
<style>
#carousel-fade{
  width: 100%;
  height: 46.875vw;
  position: fixed;
  overflow: hidden;
  margin-top: 70px;
}
.nav-a{
  text-decoration: none;
}
.studioDesc{
  text-decoration: none;
}
</style>
